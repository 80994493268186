.navbar-light .navbar-nav .nav-link {
  color: #333;
}

@media (min-width: 767px) {
  .dist {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(200,200,200,0.5);
    -webkit-filter: blur(0.111111rem);
    filter: blur(0.111111rem);
    z-index: 2;
  }

  .ct {
    background: #ffffff;
    width: 100%;
    z-index: 3;
    position: relative;
  }

  .navbar {
    z-index: 3;
    padding: 0.462963rem 0;
  }

  .tabs .nav-link {
    position: relative;
    padding: 0 0.277778rem;
  }

  .tabs .nav-link::after {
    content: "";
    width: 0;
    height: 0.037037rem;
    background-color: #f97547;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.462963rem;
    margin: auto;
    -webkit-transition: width 0.4s;
    transition: width 0.4s;
  }

  .tabs .nav-link.nav-active,
  .tabs .nav-link:hover {
    color: #f97547;
  }

  .tabs .nav-link.nav-active::after,
  .tabs .nav-link:hover::after {
    width: 100%;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    height: 1.148148rem;
    line-height: 1.148148rem;
    padding: 0 0.314815rem;
  }

  .navbar-light .navbar-nav .nav-link:hover,
  .navbar-light .navbar-nav .nav-link:focus {
    color: #f97547;
  }

  .navbar {
    margin: 0 auto;
    font-size: 0.333333rem;
    width: 22.222222rem;
  }

  .navbar .navbar-brand {
    margin-right: 0.925926rem;
    padding: 0;
    height: 1.148148rem;
    line-height: 1.148148rem;
    font-size: inherit;
  }

  .navbar .navbar-brand img {
    height: 100%;
  }

  .bt {
    padding: 0.166667rem 0.314815rem;
    background: #fff;
    border-radius: 0.037037rem;
    border: 0.018519rem solid #f97242;
    color: #f97242;
    font-size: 0.259259rem;
    outline: none;
  }

  .bt.bt-orange {
    padding-left: 0.592593rem;
    padding-right: 0.592593rem;
    background: #f97547;
    color: #ffffff;
  }

  .navbar-text {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .navbar .navbar-brand {
    display: none;
  }
}
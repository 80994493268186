.user-sider {
  position: relative;
}

.user-sider .logo {
  height: 1.185185rem;
  width: 100%;
  padding: 0.37037rem;
}

.user-sider .logo::after {
  content: "";
  display: block;
  background: rgba(255,255,255,0.2);
  width: 100%;
  height: 100%;
}

.user-sider .menu {
  position: absolute;
  width: 1.185185rem;
  height: 1.185185rem;
  text-align: center;
  top: 0;
  right: -1.185185rem;
  line-height: 1.185185rem;
  font-size: 0.333333rem;
  cursor: pointer;
}

.user-sider .menu:hover {
  background: rgba(0,0,0,0.025);
}
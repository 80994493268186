.user-layout .header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.user-layout .header__item {
  padding: 0 0.37037rem;
  font-size: 0.296296rem;
  cursor: pointer;
}

.user-layout .header__item:hover {
  background-color: rgba(255,255,255,0.2);
}
.loading {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.loading .spinner {
  font-size: 0.444444rem;
  color: #333333;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
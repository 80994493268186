.footer {
  background: #1e1d20;
  padding: 1.296296rem 0;
  color: #ffffff;
}

.footer .ct {
  display: flex;
  align-items: center;
  font-size: 0.222222rem;
  width: 22.222222rem;
  margin: 0 auto;
}

.footer .ct .logo {
  height: 1.351852rem;
  margin-right: 1.111111rem;
}

.footer .ct .logo img {
  height: 100%;
}

.footer .ct .info {
  flex: 1 1;
}

.footer .ct .info .lf {
  display: flex;
  margin-bottom: 0.37037rem;
  font-size: 0.259259rem;
}

.footer .ct .info .lf div {
  padding: 0.018519rem 0.185185rem;
  border-right: 0.018519rem solid #ffffff;
  font-weight: bold;
}

.footer .ct .email {
  padding-left: 0.185185rem;
  margin-bottom: 0.111111rem;
}

.footer .ct .email img {
  height: 0.185185rem;
  margin-right: 0.074074rem;
}

.footer .ct .qrcode {
  text-align: center;
}

.footer .ct .qrcode img {
  height: 1.481481rem;
  margin-bottom: 0.074074rem;
}

.footer .ct .qrcode p {
  color: #999999;
  text-align: center;
  margin-bottom: 0;
}

.footer .ct .link:hover {
  color: cornflowerblue;
}